import ContractTypes from "../pages/contract-types/ContractTypes";
import ContractTypesCreate from "../pages/contract-types/ContractTypesCreate";
import ContractTypesUpdate from "../pages/contract-types/ContractTypesUpdate";
import CostCenters from "../pages/cost-centers/CostCenters";
import CostCentersCreate from "../pages/cost-centers/CostCentersCreate";
import CostCentersUpdate from "../pages/cost-centers/CostCentersUpdate";
import Dashboard from "../pages/Dashboard";
import Offices from "../pages/offices/Offices";
import OfficesCreate from "../pages/offices/OfficesCreate";
import OfficesUpdate from "../pages/offices/OfficesUpdate";
import Profile from "../pages/Profile";
import Roles from "../pages/roles/Roles";
import RolesCreate from "../pages/roles/RolesCreate";
import RolesUpdate from "../pages/roles/RolesUpdate";
import Services from "../pages/services/Services";
import ServicesCreate from "../pages/services/ServicesCreate";
import ServicesUpdate from "../pages/services/ServicesUpdate";
import Users from "../pages/users/Users";
import UsersCreate from "../pages/users/UsersCreate";
import UsersUpdate from "../pages/users/UsersUpdate";
import Vacations from "../pages/vacations/Vacations";
import VacationsDetail from "../pages/vacations/VacationsDetails";
import SystemInfo from "./SystemInfo";
import Positions from "../pages/positions/Positions";
import PositionsCreate from "../pages/positions/PositionsCreate";
import PositionsUpdate from "../pages/positions/PositionsUpdate";
import UpdateVacationsDays from "../pages/users/UpdateVacationsDays";
import Holidays from "../pages/holidays/Holidays";
import HolidaysCreate from "../pages/holidays/HolidaysCreate";
import HolidaysUpdate from "../pages/holidays/HolidaysUpdate";
import HolidaysLoadFile from "../pages/holidays/HolidaysLoadFile";



import pedidos from "../assets/img/pedidos.png";
import cms from "../assets/img/cms.png";
import viajes from "../assets/img/viajes.png";
import acreditaciones from "../assets/img/acreditaciones.png";
import planificacion from "../assets/img/planificaciones.png";
import controlBi from "../assets/img/controlbi.png";
import haberes from "../assets/img/haberes.png";
import cotizaciones from "../assets/img/cotizaciones.png";
import rindefondos from "../assets/img/rindefondos.png";
import controlactivos from "../assets/img/controldeactivos.png";



import Zones from "../pages/zones/Zones";
import ZonesUpdate from "../pages/zones/ZonesUpdate";
import ZonesCreate from "../pages/zones/ZonesCreate";
import Regions from "../pages/regions/Regions";
import RegionsCreate from "../pages/regions/RegionsCreate";
import RegionsUpdate from "../pages/regions/RegionsUpdate";
import Communes from "../pages/communes/Communes";
import CommunesCreate from "../pages/communes/CommunesCreate";
import CommunesUpdate from "../pages/communes/CommunesUpdate";
import HousesCreate from "../pages/houses/HousesCreate";
import Houses from "../pages/houses/Houses";
import HousesUpdate from "../pages/houses/HousesUpdate";
import HouseReservations from "../pages/house-reservations/HouseReservations";
import HouseReservationsUpdate from "../pages/house-reservations/HouseReservationsUpdate";
import UsersImport from "../pages/users/UsersImport";
import TransferRequestsCreate from "../pages/transfer-requests/TransferRequestsCreate";
import Configuration from "../pages/Configuration";
import CostCentersImport from "../pages/cost-centers/CostCentersImport";
import FilesManagement from "../pages/FilesManagement";
import Areas from "../pages/areas/Areas";
import AreasCreate from "../pages/areas/AreasCreate";
import AreasUpdate from "../pages/areas/AreasUpdate";

const createLink = (
    title,
    forUpdate,
    component,
    icon,
    path,
    permissions,
    children
) => ({ title, forUpdate, component, icon, path, permissions, children });

const { systemCode } = SystemInfo;

export const mainPermissions = {
    dashboard: [`${systemCode}-view-dashboard`],
    users: [`${systemCode}-view-users`, `${systemCode}-create-users`, `${systemCode}-update-users`, `${systemCode}-delete-users`],
    roles: [`${systemCode}-view-roles`, `${systemCode}-create-roles`, `${systemCode}-update-roles`, `${systemCode}-delete-roles`],
    services: [`${systemCode}-view-services`, `${systemCode}-create-services`, `${systemCode}-update-services`, `${systemCode}-delete-services`],
    zones: [`${systemCode}-view-zones`, `${systemCode}-create-zones`, `${systemCode}-update-zones`, `${systemCode}-delete-zones`],
    regions: [`${systemCode}-view-regions`, `${systemCode}-create-regions`, `${systemCode}-update-regions`, `${systemCode}-delete-regions`],
    communes: [`${systemCode}-view-communes`, `${systemCode}-create-communes`, `${systemCode}-update-communes`, `${systemCode}-delete-communes`],
    houses: [`${systemCode}-view-houses`, `${systemCode}-create-houses`, `${systemCode}-update-houses`, `${systemCode}-delete-houses`],
    houseReservations: [`${systemCode}-view-house-reservations`, `${systemCode}-create-house-reservations`, `${systemCode}-update-house-reservations`, `${systemCode}-delete-house-reservations`],
    positions: [`${systemCode}-view-positions`, `${systemCode}-create-positions`, `${systemCode}-update-positions`, `${systemCode}-delete-positions`],
    holidays: [`${systemCode}-view-holidays`, `${systemCode}-create-holidays`, `${systemCode}-update-holidays`, `${systemCode}-delete-holidays`],
    offices: [`${systemCode}-view-offices`, `${systemCode}-create-offices`, `${systemCode}-update-offices`, `${systemCode}-delete-offices`],
    areas: [`${systemCode}-view-areas`, `${systemCode}-create-areas`, `${systemCode}-update-areas`, `${systemCode}-delete-areas`],
    costCenters: [`${systemCode}-view-cost-centers`, `${systemCode}-create-cost-centers`, `${systemCode}-update-cost-centers`, `${systemCode}-delete-cost-centers`],
    contractTypes: [`${systemCode}-view-contract-types`, `${systemCode}-create-contract-types`, `${systemCode}-update-contract-types`, `${systemCode}-delete-contract-types`],
    vacations: [`${systemCode}-view-vacations`, `${systemCode}-delete-vacations`, `${systemCode}-change-status-vacations`],
    fileDirs: [`${systemCode}-view-file-dirs`, `${systemCode}-create-file-dirs`, `${systemCode}-update-file-dirs`, `${systemCode}-delete-file-dirs`],
    systemFiles: [`${systemCode}-view-system-files`, `${systemCode}-create-system-files`, `${systemCode}-update-system-files`, `${systemCode}-delete-system-files`],
    configuration: [`${systemCode}-view-configuration`, `${systemCode}-update-configuration`]
}

export const MenuLinks = [
    createLink('Profile', true, <Profile />, 'ti-user', '/perfil'),

    createLink('DashBoard', null, <Dashboard />, 'ti-dashboard', '/dashboard', mainPermissions?.dashboard?.[0]),

    createLink('Usuarios', null, null, 'ti-user', '/usuarios', mainPermissions?.users, [
        createLink('Crear Usuario', null, <UsersCreate />, '', '/usuarios/crear', mainPermissions?.users?.[1]),
        createLink('Importar Usuarios', null, <UsersImport />, '', '/usuarios/importar', mainPermissions?.users?.[1]),
        createLink('Listar Usuarios', null, <Users />, '', '/usuarios', mainPermissions?.users?.[0]),
        createLink('Actualizar dias de vacaciones', true, <UpdateVacationsDays />, '', '/usuarios/cargar-dias-de-vacaciones', mainPermissions?.users?.[2]),
        createLink('Actualizar Usuarios', true, <UsersUpdate />, '', '/usuarios/:id', mainPermissions?.users?.[2]),
    ]),

    createLink('Roles', null, null, 'ti-user', '/roles', mainPermissions?.roles, [
        createLink('Crear Rol', null, <RolesCreate />, '', '/roles/crear', mainPermissions?.roles?.[1]),
        createLink('Listar Rol', null, <Roles />, '', '/roles', mainPermissions?.roles?.[0]),
        createLink('Actualizar Rol', true, <RolesUpdate />, '', '/roles/:id', mainPermissions?.roles?.[2]),
    ]),

    createLink('Servicios', null, null, 'ti-user', '/servicios', mainPermissions?.services, [
        createLink('Crear Servicio', null, <ServicesCreate />, '', '/servicios/crear', mainPermissions?.services?.[1]),
        createLink('Listar Servicios', null, <Services />, '', '/servicios', mainPermissions?.services?.[0]),
        createLink('Actualizar Servicios', true, <ServicesUpdate />, '', '/servicios/:id', mainPermissions?.services?.[2]),
    ]),

    createLink('Zonas', null, null, 'ti-user', '/zonas', mainPermissions?.zones, [
        createLink('Crear Zona', null, <ZonesCreate />, '', '/zonas/crear', mainPermissions?.zones?.[1]),
        createLink('Listar Zonas', null, <Zones />, '', '/zonas', mainPermissions?.zones?.[0]),
        createLink('Actualizar Zonas', true, <ZonesUpdate />, '', '/zonas/:id', mainPermissions?.zones?.[2]),
    ]),

    createLink('Regiones', null, null, 'ti-user', '/regiones', mainPermissions?.regions, [
        createLink('Crear Region', null, <RegionsCreate />, '', '/regiones/crear', mainPermissions?.regions?.[1]),
        createLink('Listar Regiones', null, <Regions />, '', '/regiones', mainPermissions?.regions?.[0]),
        createLink('Actualizar Region', true, <RegionsUpdate />, '', '/regiones/:id', mainPermissions?.regions?.[2]),
    ]),

    createLink('Comunas', null, null, 'ti-user', '/comunas', mainPermissions?.communes, [
        createLink('Crear Comuna', null, <CommunesCreate />, '', '/comunas/crear', mainPermissions?.communes?.[1]),
        createLink('Listar comunas', null, <Communes />, '', '/comunas', mainPermissions?.communes?.[0]),
        createLink('Actualizar comuna', true, <CommunesUpdate />, '', '/comunas/:id', mainPermissions?.communes?.[2]),
    ]),

    createLink('Casas Csi', null, null, 'ti-user', '/casas-csi', mainPermissions?.houses, [
        createLink('Crear Casa Csi', null, <HousesCreate />, '', '/casas-csi/crear', mainPermissions?.houses?.[1]),
        createLink('Listar Casas Csi', null, <Houses />, '', '/casas-csi', mainPermissions?.houses?.[0]),
        createLink('Actualizar Casa Csi', true, <HousesUpdate />, '', '/casas-csi/:id', mainPermissions?.houses?.[2]),
    ]),

    createLink('Reservas de Casas Csi', null, null, 'ti-user', '/casas-csi-reservas', mainPermissions?.houseReservations, [
        createLink('Listar Reservas de Casas Csi', null, <HouseReservations />, '', '/casas-csi-reservas', mainPermissions?.houseReservations?.[0]),
        createLink('Actualizar Reservacion Casa Csi', true, <HouseReservationsUpdate />, '', '/casas-csi-reservas/:id', mainPermissions?.houseReservations?.[2]),
    ]),

    createLink('Utilidades TI', null, <FilesManagement />, '', '/utilidades-ti', mainPermissions?.systemFiles?.[0]),

    createLink('Configuración', null, <Configuration />, '', '/configuracion', mainPermissions?.configuration?.[0]),

    createLink('Gestión de personas', null, null, 'ti-user', '/oficinas', mainPermissions?.offices, [
        createLink('Oficinas', null, <Offices />, '', '/oficinas', mainPermissions?.offices?.[0]),
        createLink('Crear', true, <OfficesCreate />, '', '/oficinas/crear', mainPermissions?.offices?.[1]),
        createLink('Actualizar Oficinas', true, <OfficesUpdate />, '', '/oficinas/:id', mainPermissions?.offices?.[2]),

        createLink('Centro de costos', null, <CostCenters />, '', '/centro-de-costos', mainPermissions?.costCenters?.[0]),
        createLink('Crear', true, <CostCentersCreate />, '', '/centro-de-costos/crear', mainPermissions?.costCenters?.[1]),
        createLink('Importar Centros de costos', null, <CostCentersImport />, '', '/centro-de-costos/importar', mainPermissions?.costCenters?.[1]),
        createLink('Actualizar Centro de Costos', true, <CostCentersUpdate />, '', '/centro-de-costos/:id', mainPermissions?.costCenters?.[2]),

        createLink('Áreas', null, <Areas />, '', '/areas', mainPermissions?.areas?.[0]),
        createLink('Crear Áreas', true, <AreasCreate />, '', '/areas/crear', mainPermissions?.areas?.[1]),
        createLink('Actualizar Área', true, <AreasUpdate />, '', '/areas/:id', mainPermissions?.areas?.[2]),

        createLink('Tipos de contratos', null, <ContractTypes />, '', '/tipo-de-contratos', mainPermissions?.contractTypes?.[0]),
        createLink('Crear', true, <ContractTypesCreate />, '', '/tipo-de-contratos/crear', mainPermissions?.contractTypes?.[1]),
        createLink('Actualizar Tipo de Contrato', true, <ContractTypesUpdate />, '', '/tipo-de-contratos/:id', mainPermissions?.contractTypes?.[2]),

        createLink('Vacaciones', null, <Vacations />, '', '/vacaciones', mainPermissions?.vacations?.[0]),
        createLink('Vacaciones Detalle', true, <VacationsDetail />, '', '/vacaciones/:id', mainPermissions?.vacations?.[0]),

        createLink('Solicitud de traslado', null, <TransferRequestsCreate />, '', '/solicitud-de-traslado', mainPermissions?.vacations?.[0]),

        createLink('Cargos', null, <Positions />, '', '/cargos', mainPermissions?.positions?.[0]),
        createLink('Crear Cargo', true, <PositionsCreate />, '', '/cargos/crear', mainPermissions?.positions?.[1]),
        createLink('Actualizar Cargos', true, <PositionsUpdate />, '', '/cargos/:id', mainPermissions?.positions?.[2]),

        createLink('Dias Feriados', null, <Holidays />, '', '/dias-feriados', mainPermissions?.holidays?.[0]),
        createLink('Crear Dia Feriado', true, <HolidaysCreate />, '', '/dias-feriados/crear', mainPermissions?.holidays?.[1]),
        createLink('Actualizar Dia Feriado', true, <HolidaysLoadFile />, '', '/dias-feriados/cargar-excel', mainPermissions?.holidays?.[1]),
        createLink('Actualizar Dia Feriado', true, <HolidaysUpdate />, '', '/dias-feriados/:id', mainPermissions?.holidays?.[2]),
    ]),
];

export const administrationLinks = {
    name: 'Admin y Finanzas',
    icon: 'ti-bar-chart',
    adminUrl: '/administrar?linkName=administrationLinks',
    managementUrl: '/gestionar?linkName=administrationLinks',
    aplicationsUrl: '/aplicaciones?linkName=administrationLinks',
    externalLinksUrl: '/enlaces-externos?linkName=admin',
    aplications: [
        {
            name: 'Pedidos',
            siteUrl: 'https://csipedidos.csiltda.cl/iniciar-sesion',
            code: 'sys-002',
            image: pedidos
        },
        {
            name: 'CMS',
            siteUrl: 'https://csiltda.cl/admin/login',
            code: 'sys-003',
            image: cms
        },
        {
            name: 'Viajes',
            siteUrl: 'https://csiviajes.csiltda.cl/iniciar-sesion',
            code: 'sys-006',
            image: viajes
        },
        {
            name: 'Planificación Anual',
            siteUrl: 'https://csiplanificaciones.csiltda.cl/iniciar-sesion',
            code: 'sys-009',
            image: planificacion
        },
        {
            name: 'Control Bi',
            siteUrl: 'https://csicontrolbi.csiltda.cl/iniciar-sesion',
            code: 'sys-011',
            image: controlBi
        },
        {
            name: 'Sistema de cotizaciones',
            siteUrl: 'http://csicotizaciones.csiltda.cl/login',
            code: 'sys-014',
            image: cotizaciones
        },
        {
            name: 'Rendiciones',
            siteUrl: 'https://csirindefondos.csiltda.cl/auth/iniciar-sesion',
            code: 'sys-015',
            image: rindefondos
        },
        {
            name: 'Control de activos',
            siteUrl: 'https://csicontrolinventario.csiltda.cl/auth/iniciar-sesion',
            code: 'sys-007',
            image: controlactivos
        }
    ],
    adminLinks: [

    ],
    managementLinks: [

    ],
    adminPermissions: [
    ]
}

export const personManagementLinks = {
    name: 'Gestión de personas',
    icon: 'fas fa-users',
    adminUrl: '/administrar?linkName=personManagementLinks',
    managementUrl: '/gestionar?linkName=personManagementLinks',
    aplicationsUrl: '/aplicaciones?linkName=personManagementLinks',
    externalLinksUrl: '/enlaces-externos?linkName=person',
    aplications: [
        {
            name: 'Acreditaciones',
            siteUrl: 'https://csiacreditaciones.csiltda.cl/iniciar-sesion',
            code: 'sys-005',
            image: acreditaciones
        },
        {
            name: 'Control Bi',
            siteUrl: 'https://csicontrolbi.csiltda.cl/iniciar-sesion',
            code: 'sys-011',
            image: controlBi
        },
        {
            name: 'Gestión de haberes',
            siteUrl: 'https://csihaberes.csiltda.cl/iniciar-sesion',
            code: 'sys-004',
            image: haberes
        }
    ],
    adminLinks: [
        {
            moduleName: 'Oficinas',
            icon: 'fas fa-building',
            permissions: mainPermissions?.offices,
            links: [
                {
                    name: 'Listar',
                    url: '/oficinas',
                    permission: mainPermissions?.offices?.[0]
                },
                {
                    name: 'Crear',
                    url: '/oficinas/crear',
                    permission: mainPermissions?.offices?.[1]
                }
            ]
        },
        {
            moduleName: 'Centro de costo',
            icon: 'fas fa-closed-captioning',
            permissions: mainPermissions?.costCenters,
            links: [
                {
                    name: 'Listar',
                    url: '/centro-de-costos',
                    permission: mainPermissions?.costCenters?.[0]
                },
                {
                    name: 'Crear',
                    url: '/centro-de-costos/crear',
                    permission: mainPermissions?.costCenters?.[1]
                }
            ]
        },
        {
            moduleName: 'Tipos de contratos',
            icon: 'far fa-clipboard',
            permissions: mainPermissions?.contractTypes,
            links: [
                {
                    name: 'Listar',
                    url: '/tipo-de-contratos',
                    permission: mainPermissions?.contractTypes?.[0]
                },
                {
                    name: 'Crear',
                    url: '/tipo-de-contratos/crear',
                    permission: mainPermissions?.contractTypes?.[1]
                }
            ]
        },
        {
            moduleName: 'Vacaciones',
            icon: 'far fa-calendar-alt',
            permissions: mainPermissions?.vacations,
            links: [
                {
                    name: 'Listar',
                    url: '/vacaciones',
                    permission: mainPermissions?.vacations?.[0]
                }
            ]
        },
        {
            moduleName: 'Cargos',
            icon: 'fas fa-project-diagram',
            permissions: mainPermissions?.positions,
            links: [
                {
                    name: 'Listar',
                    url: '/cargos',
                    permission: mainPermissions?.positions?.[0]
                },
                {
                    name: 'Crear',
                    url: '/cargos/crear',
                    permission: mainPermissions?.positions?.[1]
                }
            ]
        },
        {
            moduleName: 'Días Feriados',
            icon: 'far fa-calendar-times',
            permissions: mainPermissions?.holidays,
            links: [
                {
                    name: 'Listar',
                    url: '/dias-feriados',
                    permission: mainPermissions?.positions?.[0]
                },
                {
                    name: 'Crear',
                    url: '/dias-feriados/crear',
                    permission: mainPermissions?.positions?.[1]
                }
            ]
        },
        {
            moduleName: 'Reservas de casas csi',
            icon: 'fas fa-building',
            permissions: mainPermissions?.houseReservations,
            links: [
                {
                    name: 'Listar',
                    url: '/casas-csi-reservas',
                    permission: mainPermissions?.houseReservations?.[0]
                }
            ]
        },
    ],
    managementLinks: [
        /* {
            moduleName: 'Vacaciones',
            icon: 'far fa-calendar-alt',
            links: [
                {
                    name: 'Ver',
                    url: '/perfil?tab=3'
                }
            ]
        } */
    ],
    adminPermissions: [
        ...mainPermissions?.positions,
        ...mainPermissions?.holidays,
        ...mainPermissions?.offices,
        ...mainPermissions?.costCenters,
        ...mainPermissions?.contractTypes,
        ...mainPermissions?.vacations
    ]
}

export const ssmaLinks = {
    name: 'SSMA',
    icon: 'fas fa-hard-hat',
    adminUrl: '/administrar?linkName=ssmaLinks',
    managementUrl: '/gestionar?linkName=ssmaLinks',
    aplicationsUrl: '/aplicaciones?linkName=ssmaLinks',
    externalLinksUrl: '/enlaces-externos?linkName=ssma',
    aplications: [
        {
            name: 'Control Bi',
            siteUrl: 'https://csicontrolbi.csiltda.cl/iniciar-sesion',
            code: 'sys-011',
            image: controlBi
        }
    ],
    adminLinks: [
    ],
    managementLinks: [

    ],
    adminPermissions: [
    ]
}

export const operationsLinks = {
    name: 'Operaciones',
    icon: 'fas fa-industry',
    adminUrl: '/administrar?linkName=operationsLinks',
    managementUrl: '/gestionar?linkName=operationsLinks',
    aplicationsUrl: '/aplicaciones?linkName=operationsLinks',
    externalLinksUrl: '/enlaces-externos?linkName=operation',
    aplications: [
        {
            name: 'Control Bi',
            siteUrl: 'https://csicontrolbi.csiltda.cl/iniciar-sesion',
            code: 'sys-011',
            image: controlBi
        }
    ],
    adminLinks: [
        {
            moduleName: 'Zonas',
            icon: 'fas fa-industry',
            permissions: mainPermissions?.zones,
            links: [
                {
                    name: 'Listar',
                    url: '/zonas',
                    permission: mainPermissions?.zones?.[0]
                },
                {
                    name: 'Crear',
                    url: '/zonas/crear',
                    permission: mainPermissions?.zones?.[1]
                }
            ]
        },
        {
            moduleName: 'Servicios',
            icon: 'fas fa-industry',
            permissions: mainPermissions?.services,
            links: [
                {
                    name: 'Listar',
                    url: '/servicios',
                    permission: mainPermissions?.services?.[0]
                },
                {
                    name: 'Crear',
                    url: '/servicios/crear',
                    permission: mainPermissions?.services?.[1]
                }
            ]
        }
    ],
    managementLinks: [

    ],
    adminPermissions: [
        ...mainPermissions.services
    ]
}


export const configLinks = {
    name: 'Configuración',
    icon: 'ti-settings',
    adminUrl: '/administrar?linkName=configLinks',
    managementUrl: '/gestionar?linkName=configLinks',
    aplicationsUrl: '/aplicaciones?linkName=configLinks',
    externalLinksUrl: '/enlaces-externos?linkName=configuration',
    aplications: [],
    adminLinks: [
        {
            moduleName: 'Usuarios',
            icon: 'fas fa-user',
            permissions: mainPermissions?.users,
            links: [
                {
                    name: 'Listar',
                    url: '/usuarios',
                    permission: mainPermissions?.users?.[0]
                },
                {
                    name: 'Crear',
                    url: '/usuarios/crear',
                    permission: mainPermissions?.users?.[1]
                }
            ]
        },
        {
            moduleName: 'Roles',
            icon: 'fas fa-users-cog',
            permissions: mainPermissions?.roles,
            links: [
                {
                    name: 'Listar',
                    url: '/roles',
                    permission: mainPermissions?.roles?.[0]
                },
                {
                    name: 'Crear',
                    url: '/roles/crear',
                    permission: mainPermissions?.roles?.[1]
                }
            ]
        },
        {
            moduleName: 'Regiones',
            icon: 'ti-map',
            permissions: mainPermissions?.regions,
            links: [
                {
                    name: 'Listar',
                    url: '/regiones',
                    permission: mainPermissions?.regions?.[0]
                },
                {
                    name: 'Crear',
                    url: '/regiones/crear',
                    permission: mainPermissions?.regions?.[1]
                }
            ]
        },
        {
            moduleName: 'Comunas',
            icon: 'ti-map-alt',
            permissions: mainPermissions?.communes,
            links: [
                {
                    name: 'Listar',
                    url: '/comunas',
                    permission: mainPermissions?.communes?.[0]
                },
                {
                    name: 'Crear',
                    url: '/comunas/crear',
                    permission: mainPermissions?.communes?.[1]
                }
            ]
        },
        {
            moduleName: 'Áreas',
            icon: 'fas fa-fax',
            permissions: mainPermissions?.areas,
            links: [
                {
                    name: 'Listar',
                    url: '/areas',
                    permission: mainPermissions?.areas?.[0]
                },
                {
                    name: 'Crear',
                    url: '/areas/crear',
                    permission: mainPermissions?.areas?.[1]
                }
            ]
        },
        {
            moduleName: 'Casas csi',
            icon: 'ti-home',
            permissions: mainPermissions?.houses,
            links: [
                {
                    name: 'Listar',
                    url: '/casas-csi',
                    permission: mainPermissions?.houses?.[0]
                },
                {
                    name: 'Crear',
                    url: '/casas-csi/crear',
                    permission: mainPermissions?.houses?.[1]
                }
            ]
        },
        {
            moduleName: 'Configuratión',
            icon: 'ti-settings',
            permissions: mainPermissions?.configuration,
            links: [
                {
                    name: 'Ver',
                    url: '/configuracion',
                    permission: mainPermissions?.configuration?.[0]
                },
            ]
        }
    ],
    managementLinks: [

    ],
    adminPermissions: [
        ...mainPermissions.users,
        ...mainPermissions.roles
    ]
}

export const sideBarLinks = [
    administrationLinks,
    personManagementLinks,
    operationsLinks,
    ssmaLinks,
    configLinks
]

export default {
    mainPermissions,
    MenuLinks,
    administrationLinks,
    personManagementLinks,
    ssmaLinks,
    operationsLinks,
    configLinks,
    sideBarLinks,
}